import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Img from 'gatsby-image';
import { graphql, Link, withPrefix } from 'gatsby';
import HeroCarousel from '../components/carousel/hero-carousel';

const Exchanges1031 = ({ data }) => {
    const { heroImage, hyve1, hyve2, hyve3, jade, west, entrada, farms, banner } = data;

    return (
        <Layout>
            <SEO title="1031 Exchanges" />
            <div className="page-1031">
                <section
                    className="page-1031-hero page-1031-hero--no-margin no-container"
                    data-component="hero"
                >
                    {/* <Img
                        fluid={heroImage.childImageSharp.fluid}
                        alt={''}
                        className="page-1031-hero__background"
                    /> */}
                    <div className="container">
                        <h1 className="heading1">
                            We are Experts at <strong>1031 Exchanges</strong>
                        </h1>
                        <Link className="btn btn-primary" to="/contact">
                            Book a consultation
                        </Link>
                    </div>
                </section>
                <section className="page-1031-description no-container">
                    <div className="container">
                        <div className="page-1031-description__content">
                            <h2>1031 Exchanges Made Easy</h2>
                            <p>
                                Exchange with ease into our{' '}
                                <strong>Delaware Statutory Trusts ("DSTs")</strong> for
                                1031-eligible fractional ownership of Class A passive real estate.
                            </p>
                            <p>
                                Want a more customized experience for your larger exchange? We offer{' '}
                                <strong>IDEAL Private Client</strong>, a custom-tailored fund just
                                for you.
                            </p>
                        </div>
                        <div className="page-1031-description__image-container">
                            <Img fluid={banner.childImageSharp.fluid} alt="" />
                        </div>
                    </div>
                </section>
                <section className="page-1031-comparison">
                    <div>
                        <h2>Delaware Statutory Trusts</h2>
                        <p>Passively Exchange into Institutional-Quality Real Estate</p>

                        <h3>Key Points:</h3>
                        <ul>
                            <li>
                                A DST is a IRS-approved 1031-exchange eligible property-owning
                                entity.
                            </li>
                            <li>
                                We buy the asset, create the DST, and allow investors to come into
                                the fund after they sell their own properties.{' '}
                            </li>
                            <li>Our DSTs aim for monthly cash flow and equity growth.</li>
                            <li>
                                DSTs are completely passive, meaning you have no responsibilities
                                for management or reporting—we do it all.{' '}
                            </li>
                            <li>You have limited liability and no loan recourse.</li>
                            <li>You can invest some, most, or all of your 1031 exchange.</li>
                            <li>You can exchange into and out of the fund when it sells.</li>
                        </ul>
                        <Link style={{marginRight: 20}} className="btn btn-primary btn-primary--light" to="/dst">
                            Learn More
                        </Link>
                        <br />
                        <br />
                        <a
                                    className="btn btn-primary"
                                    rel="noopener noreferrer"
                                    href={withPrefix(
                                        '/pdfs/IDEAL_Capital_Group_DST_Overivew_(Print).pdf'
                                    )}
                                    target="_blank"
                                >
                                    {' '}
                                    Download PDF
                        </a>
                    </div>
                    <div>
                        <h2>IDEAL Private Client</h2>
                        <p>Retain control, give up responsibilities.</p>
                        <h3>Key Points:</h3>
                        <ul>
                            <li>
                                IDEAL Private Client is a real estate investment structure that
                                allows you to directly and passively own institutional-quality
                                multifamily real estate.
                            </li>
                            <li>
                                With IDEAL Private Client, we will identify, acquire, and manage
                                high-quality multifamily property on your behalf, providing you
                                monthly cash flow and institutional-quality asset management and
                                reporting — all while you remain the sole owner and key decision
                                maker of the real estate.
                            </li>
                            <li>
                                With IDEAL Private Client, you will benefit from the economies of
                                scale and pricing power of our multi-billion dollar multifamily
                                portfolio, saving money on everything from appliance replacement to
                                insurance costs.
                            </li>
                        </ul>
                        <Link style={{marginRight: 20}} className="btn btn-primary btn-primary--light" to="/private-client">
                            Learn More
                        </Link>
                        <br />
                        <br />
                        <a
                                    className="btn btn-primary"
                                    rel="noopener noreferrer"
                                    href={withPrefix(
                                        '/pdfs/IDEAL_Private_Client_Brochure_(Print_Version).pdf'
                                    )}
                                    target="_blank"
                                >
                                    {' '}
                                    Download PDF
                        </a>    
                    </div>
                </section>

                {/* <section className="page-1031-banner no-container">
                    <Img fluid={banner.childImageSharp.fluid} alt="" />
                </section> */}
                <section className="page-1031-contact">
                    <h2>Contact Us</h2>
                    <div className="page-1031-contact__grid">
                        <div>
                            <h3>Mailing Address</h3>
                            <p>3128 Willow Ave., Suite 101 Clovis, CA 93612</p>
                        </div>
                        <div>
                            <h3>Email Address</h3>
                            <p>
                                <a href="mailto:kevin@idealcapgroup.com">kevin@idealcapgroup.com</a>
                            </p>
                        </div>

                        <div>
                            <h3>Phone Number</h3>
                            <p>
                                <a href="tel:559-347-6980">(559) 347-6980</a>
                            </p>
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default Exchanges1031;

export const query = graphql`
    query {
        hyve1: file(relativePath: { in: "1031-hyve-1-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hyve2: file(relativePath: { in: "1031-hyve-2-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        hyve3: file(relativePath: { in: "1031-hyve-3-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        jade: file(relativePath: { in: "1031-jade-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        west: file(relativePath: { in: "1031-west-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        farms: file(relativePath: { in: "1031-farms-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        entrada: file(relativePath: { in: "1031-entrada-optimized.jpeg" }) {
            childImageSharp {
                fluid(maxWidth: 700, maxHeight: 700) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        heroImage: file(relativePath: { in: "1031-banner.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        banner: file(relativePath: { in: "1031-new-banner.webp" }) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`;
